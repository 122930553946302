import React from "react"
import Layout from "../components/global/layout"
import SEO from "../components/global/seo"
import SingleProduct from "../components/single-product-page/singleProduct"

import medplusStickProduct from "../images/product-and-package/mp-stick.jpg"
import medplusImage from "../images/products-without-packaging/medplus.png"

// related product images
import medplusProduct from "../images/product-and-package/mp.jpg"
import intensiveMoisturiserProduct from "../images/product-and-package/inm.jpg"

import homeBargainsLogo from "../images/stockists/homebargains.png"
import superdrugLogo from "../images/stockists/superdrug.png"

import coldIcon from "../images/icons/product-icon/winter.svg"
import outdoorIcon from "../images/icons/product-icon/outdoors.svg"

const product = {
  image: medplusStickProduct,
  productImage: medplusImage,
  title: <h1 className="product-overview__heading">MedPlus Stick.</h1>,
  alt: "MedPlus Stick.",
  subTitle: (
    <h2 className="product-overview__sub-heading">
      <br />
      Blistex MedPlus Stick is perfect when lips need some TLC.
      <br />
    </h2>
  ),
  disclaimer: "",
  icons: [
    { title: "outdoors", icon: outdoorIcon },
    { title: "cold weather", icon: coldIcon },
  ],
  overviewCopy: ( 
    <div>
      <br />
      <p>
      Blistex MedPlus® special combination of ingredients, such as Menthol, Camphor, Thymol and Beeswax, provides immediate cooling & soothing action and long-lasting moisturization for dry lips. Even when they are at their most distressed.
      </p>
    </div>
  ),
  listID: "",
  stockists: [
    {
      stockistName: "HomeBargains",
      stockistImage: homeBargainsLogo,
      stockistLink:
        "",
      classModifier: "",
      showLink: "false",
    },
    {
      stockistName: "Superdrug",
      stockistImage: superdrugLogo,
      stockistLink: "https://www.superdrug.com/health/medicated-skin/medicated-skin-treatments/blistex-med-plus-stick/p/840480",
      classModifier: "",
      showLink: "true",
    },
  ],
  whenToUseCopy: (
    <div>
      <p>
      Gently apply the balm evenly all over your lips. Re-apply as often as you like, especially when lips feel sore or dry, or if you are out and about in dry or cold weather. Not suitable for children under 3 years. Consult a physician during pregnancy/lactation.
      </p>
    </div>
  ),
  ingredients: (
    <p>
      hydrogenated coconut oil, cera alba, lanolin, ricinus communis (castor) seed oil, jojoba esters, vitis vinifera (grape) seed oil, camphor, menthol, olea europaea (olive) fruit oil, benzyl alcohol, beta-carotene, butyrospermum parkii (shea) butter, daucus carota sativa (carrot) root extract, eucalyptus globulus leaf oil, eugenia caryophyllus (clove) flower oil, eugenol, glycine soja (soybean) oil, limonene, menthyl lactate, parfum, saccharin, thymol, tocopherol, zea mays (corn) oil
    </p>
  ),
  faqs: [
    {
      question:
        "Does this product contain any ingredients derived from animals?",
      answer: "Yes, Beeswax and Lanolin",
    },
    {
      question: "Do you sell this product online? ",
      answer:
        "MedPlus Stick is available to buy online through key retailers.  Please click on the where to buy link on each product page.",
    },
    {
      question: "Does this product contain perfume?",
      answer: "No, only flavourings.",
    },
    {
      question: "Does this product contain any parabens?",
      answer: "No.",
    },
  ],
  relatedProducts: [
    {
      productName: "MedPlus Jar.",
      productLink: "/medplus",
      productImage: medplusProduct,
    },
    {
      productName: "intensive moisturiser.",
      productLink: "/intensive-moisturiser",
      productImage: intensiveMoisturiserProduct,
    },
  ],
}

const MedPlus = () => (
  <Layout noHeaderInLayout={false} makeFooterSticky={false}>
    <SEO title="MedPlus Stick." />
    <SingleProduct data={product} inStock={true} outOfStockMsg={null} />
  </Layout>
)

export default MedPlus
